import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalOfferTiles from "../components/globalOfferTiles"
import LastPosts from "../components/lastPosts"
import TestimonialsSlider from "../components/testimonialsSlider"

const IndexPage = () => {
  return (
    <Layout>
      {/* eslint-disable jsx-a11y/label-has-associated-control */}
      <SEO
        seoTitle="Omeda-Med - profesjonalne centrum medyczne w Krakowie"
        description="Poradnia podologiczna Omeda-Med w Krakowie to zespół specjalistów, którzy kompleksowo zadbają o Twoje zdrowie."
      />

      <section className="home-top">
        <div className="home-top__inner">
          <h1>Centrum Medyczne</h1>
          <h2>Omeda-Med</h2>
          <ul>
            <li>
              <Link to="/oferta/kriochirurgia/">Kriochirurgia</Link>
            </li>
            <li>
              <Link to="/oferta/podologia/">Podologia</Link>
            </li>
            <li>
              <Link to="/oferta/fizjoterapia/">Fizjoterapia</Link>
            </li>
            <li>
              <Link to="/oferta/chirurgia-palca/">Chirurgia palca</Link>
            </li>
            <li>
              <Link to="/medika-1/">Angiologia</Link>
            </li>
            <li>
              <Link to="/oferta/pielegniarstwo/">Usługi pielęgniarskie</Link>
            </li>
          </ul>
        </div>
        <a href="tel:+48600314600" className="home-top__cta">
          <strong>Umów wizytę</strong> 600 314 600
        </a>
      </section>

      <section className="home-about">
        <div className="container">
          <div className="row align-item-center">
            <div className="col-md-6">
              <label className="section--label">O nas</label>
              <h2 className="section--header">
                Najwyższe standardy
                <strong>diagnostyczne, lecznicze i rehabilitacyjne</strong>
              </h2>
              <p>
                Centrum Medyczne Omeda-Med to zespół specjalistów, którzy
                kompleksowo zadbają o Twoje zdrowie. Wykwalifikowani eksperci
                zapewnią trafną diagnozę oraz profesjonalne leczenie wszelkich
                dolegliwości wpływających na komfort codziennego funkcjonowania.
                Sprawimy, że poczujesz się zdrowy, silny i piękny! Poza rozległą
                wiedzą i bogatym doświadczeniem oferujemy dostęp do nowoczesnego
                sprzętu diagnostycznego, leczniczego i rehabilitacyjnego.
                Postaramy się, żeby każdy Twój dzień stał się prawdziwą
                przyjemnością!
              </p>
              <ul>
                <li>Podolodzy</li>
                <li>Ortopedzi</li>
                <li>Flebolodzy</li>
                <li>Fizjoterapeuci</li>
              </ul>
              <Link
                to="/nasi-specjalisci/"
                className="btn-custom btn-custom--purple"
              >
                Nasi specjaliści
              </Link>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src={require("../assets/images/home-about-new.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="home-services">
        <div className="container">
          <label className="section--label text-center">Nasze usługi</label>
          <h2 className="section--header text-center">
            Dlaczego warto
            <strong>skorzystać z naszych usług</strong>
          </h2>
          <div className="row align-item-center">
            <div className="col-md-4">
              <div className="home-services__thumb">
                <img
                  className="img-fluid"
                  src={require("../assets/images/home-services-image-1.svg")}
                  alt=""
                />
              </div>
              <p>
                <strong>Kochamy</strong> to co robimy
              </p>
            </div>
            <div className="col-md-4">
              <div className="home-services__thumb">
                <img
                  className="img-fluid"
                  src={require("../assets/images/home-services-image-2.svg")}
                  alt=""
                />
              </div>
              <p>
                <strong>Lekarze</strong>z dużym doświadczeniem
              </p>
            </div>
            <div className="col-md-4">
              <div className="home-services__thumb">
                <img
                  className="img-fluid"
                  src={require("../assets/images/home-services-image-3.svg")}
                  alt=""
                />
              </div>
              <p>
                <strong>Atrakcyjne</strong> ceny
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="home-numbers">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="home-numbers__tile">
                <span>6</span>
                <p>
                  <strong>Doświadczonych</strong> specjalistów
                </p>
              </div>
              <div className="home-numbers__tile">
                <span>300</span>
                <p>
                  <strong>Potwierdzonych referencji</strong> w 2020
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <img
                className="img-fluid"
                src={require("../assets/images/home-numbers-image.png")}
                srcSet={require("../assets/images/home-numbers-image@2x.png")}
                alt=""
              />
            </div>
            <div className="col-md-4">
              <div className="home-numbers__tile">
                <span>10</span>
                <p>
                  <strong>Lat</strong> doświadczenia
                </p>
              </div>
              <div className="home-numbers__tile">
                <span>10 000</span>
                <p>
                  <strong>Przeprowadzonych</strong> zabiegów
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-offer">
        <div className="container">
          <div className="row align-item-center">
            <div className="col-xl-6 col-lg-5 col-left">
              <label className="section--label">Oferta</label>
              <h2 className="section--header">
                Kompleksowa opieka medyczna
                <strong>w jednym miejscu</strong>
              </h2>
              <p>
                Omeda-Med to placówka medyczna tworzona przez zespół
                specjalistów, którzy zadbają o Twoje zdrowie i dobre
                samopoczucie. Doświadczenie, kwalifikacje oraz profesjonalne
                wyposażenie gabinetów pozwalają nam świadczyć usługi na
                najwyższym poziomie. Bogata oferta zabiegów to odpowiedź na
                zróżnicowane potrzeby Pacjentów - pragniemy pomóc każdemu, kto
                na nas liczy. Wszystko to robimy z prawdziwym zaangażowaniem, bo
                Twoje zdrowie to nasza pasja!
              </p>
              <Link to="/oferta/" className="btn-custom btn-custom--purple">
                Dowiedz się więcej
              </Link>
            </div>
            <div className="col-xl-6 col-lg-7">
              <GlobalOfferTiles />
            </div>
          </div>
        </div>
      </section>

      <section className="home-testimonials">
        <label className="section--label text-center">Referencje</label>
        <h2 className="section--header text-center">Klienci o nas</h2>
        <TestimonialsSlider />
      </section>

      <section className="home-blog">
        <div className="container">
          <label className="section--label text-center">Blog</label>
          <h2 className="section--header text-center">Ciekawe porady</h2>
          <LastPosts />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
