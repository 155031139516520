import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Slider from "react-slick"
import LazyLoad from "react-lazyload"

const TestimonialsSlider = ({ allWpOpinion }) => {
  const testimonialsSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          variableWidth: false,
          adaptiveHeight: true,
        },
      },
    ],
  }

  return (
    <Slider {...testimonialsSlider}>
      {allWpOpinion.nodes.map((node, index) => (
        <LazyLoad key={index}>
          <div>
            <div className="home-testimonials__tile">
              <div className="home-testimonials__tile-top">
                <div>
                  <p>{node.title}</p>
                  <div className="home-testimonials__tile-stars">
                    {node.acfOpinions.opinionStars > 0 ? (
                      <span></span>
                    ) : (
                      <span className="empty"></span>
                    )}
                    {node.acfOpinions.opinionStars > 1 ? (
                      <span></span>
                    ) : (
                      <span className="empty"></span>
                    )}
                    {node.acfOpinions.opinionStars > 2 ? (
                      <span></span>
                    ) : (
                      <span className="empty"></span>
                    )}
                    {node.acfOpinions.opinionStars > 3 ? (
                      <span></span>
                    ) : (
                      <span className="empty"></span>
                    )}
                    {node.acfOpinions.opinionStars > 4 ? (
                      <span></span>
                    ) : (
                      <span className="empty"></span>
                    )}
                  </div>
                </div>
                <img
                  className="img-fluid"
                  src={require(`../assets/images/logo-${node.acfOpinions.opinionSource}.svg`)}
                  alt={`Omeda-Med ${node.title}`}
                />
              </div>
              <div className="home-testimonials__tile-content">
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: node.content.substring(0, 340),
                    }}
                  />
                  {node.content.length > 240 && (
                    <>
                      ... <Link to={`/opinie-klientow${node.uri}`}>Więcej</Link>
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </LazyLoad>
      ))}
    </Slider>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        allWpOpinion: allWpOpinion(limit: 10) {
          nodes {
            uri
            id
            slug
            title
            content
            excerpt
            acfOpinions {
              opinionSource
              opinionStars
            }
          }
        }
      }
    `}
    render={data => <TestimonialsSlider {...data} />}
  />
)
